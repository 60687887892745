import {Product} from '@shopify/hydrogen/storefront-api-types';

// Demo store placeholders
const PLACEHOLDERS = {
  HEROS: [
    // primaryHero
    {
      heading: {value: 'New Arrivals'},
      byline: {
        value: 'All new pieces, carefully curated.',
      },
      cta: {value: 'Shop Now →'},
      handle: 'new-arrivals',
      spread: {
        reference: {
          mediaContentType: 'IMAGE',
          alt: 'New white couch with natural coffee table',
          previewImage: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/REN-L04533-2-2.jpg?v=1680883379',
          },
          id: 'gid://shopify/MediaImage/23260709978179',
          image: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/REN-L04533-2-2.jpg?v=1680883379',
            width: 4000,
            height: 4000,
          },
        },
      },
      spreadSecondary: {
        reference: {
          __typename: 'MediaImage',
          mediaContentType: 'IMAGE',
          alt: 'Future living room',
          previewImage: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/Slab-Morse_1-1.jpg?v=1680883541',
          },
          id: 'gid://shopify/MediaImage/23260714467395',
          image: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/Slab-Morse_1-1.jpg?v=1680883541',
            width: 4000,
            height: 4000,
          },
        },
      },
      height: 'full',
      top: true,
      decoding: 'sync',
      loading: 'eager',
    },
    // secondaryHero
    {
      heading: {value: 'TOV Furniture'},
      byline: null,
      cta: {value: 'Shop Now →'},
      handle: 'tov-furniture',
      spread: {
        reference: {
          __typename: 'MediaImage',
          mediaContentType: 'IMAGE',
          alt: 'Chairs around a table',
          previewImage: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/TOV-D44123-D7235.jpg?v=1680931112',
          },
          id: 'gid://shopify/MediaImage/23262012309571',
          image: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/TOV-D44123-D7235.jpg?v=1680931112',
            width: 4000,
            height: 4000,
          },
        },
      },
      spreadSecondary: {
        reference: {
          __typename: 'MediaImage',
          mediaContentType: 'IMAGE',
          alt: 'White bed with patterned bg',
          previewImage: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/TOV-B6376-TOV-B6377_1c2e0391-523c-4e54-b23b-8b299ae9acfe.jpg?v=1680931073',
          },
          id: 'gid://shopify/MediaImage/23262012112963',
          image: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/TOV-B6376-TOV-B6377_1c2e0391-523c-4e54-b23b-8b299ae9acfe.jpg?v=1680931073',
            width: 4000,
            height: 4000,
          },
        },
      },
    },
    // tertiaryHero
    {
      heading: {value: 'Closeout Corner'},
      byline: {value: ''},
      cta: {value: 'Shop Now →'},
      handle: 'tov-closeouts',
      spread: {
        reference: {
          __typename: 'MediaImage',
          mediaContentType: 'IMAGE',
          alt: 'Quiet nook in the corner',
          previewImage: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/GreeningtonHannaSideboardG-22-15-RS-1_1080x_43f78dff-b09f-4202-9f6e-7bf3bb5f1dda.jpg?v=1680929560',
          },
          id: 'gid://shopify/MediaImage/23262006771779',
          image: {
            url: 'https://cdn.shopify.com/s/files/1/0783/4877/files/GreeningtonHannaSideboardG-22-15-RS-1_1080x_43f78dff-b09f-4202-9f6e-7bf3bb5f1dda.jpg?v=1680929560',
            width: 4000,
            height: 4000,
          },
        },
      },
      spreadSecondary: null,
    },
  ],
  PRODUCT_INFO: [
    {
      title: 'Description',
      content: 'Product description loading',
    },
    {
      title: 'Size and Fit',
      content: 'Product size and fit loading',
    },
    {
      title: 'Delivery and Returns',
      content: 'Product delivery and returns loading',
    },
  ],
  PRODUCT: {
    label: 'Loading product…',
    id: 'gid://shopify/Product/6555190558787',
    title: 'Minimal & Modern Product',
    publishedAt: '2021-06-17T18:33:17Z',
    handle:
      'tov-furniture-modern-jezebel-cream-velvet-wingback-chair-tov-ihs68205',
    description: 'Loading product description…',
    priceRange: {
      minVariantPrice: {
        amount: '775.0',
        currencyCode: 'CAD',
      },
      maxVariantPrice: {
        amount: '775.0',
        currencyCode: 'CAD',
      },
    },
    options: [
      {
        name: 'Color',
        values: ['Morning', 'Evening', 'Night'],
      },
      {
        name: 'Size',
        values: ['154', '158', '160'],
      },
    ],
    variants: {
      nodes: [
        {
          id: 'gid://shopify/ProductVariant/5278065985',
          image: {
            url: '#',
            altText: 'Product Image Loading',
            width: 1200,
            height: 1504,
          },
          price: {
            amount: '775.0',
            currencyCode: 'CAD',
          },
          compareAtPrice: {
            amount: '840.0',
            currencyCode: 'CAD',
          },
        },
      ],
    },
  },
};

/**
 * getHeroPlaceholder() returns placeholder content when the expected metafields
 * don't exist. Define the following five custom metafields on your Shopify store to override placeholders:
 * - hero.title             Single line text
 * - hero.byline            Single line text
 * - hero.cta               Single line text
 * - hero.spread            File
 * - hero.spread_secondary   File
 *
 * @see https://help.shopify.com/manual/metafields/metafield-definitions/creating-custom-metafield-definitions
 * @see https://github.com/Shopify/hydrogen/discussions/1790
 */

export function getHeroPlaceholder(heros: any[]) {
  if (!heros?.length) return [];

  // when we pass a collection without metafields,
  // we merge it with placeholder data
  return heros.map((hero, index) => {
    // assume passed hero has metafields data already
    // if (hero?.heading?.value) {
    //   return hero;
    // }

    // hero placeholder
    const placeholder = PLACEHOLDERS.HEROS[index];

    // prioritize metafield data if available, else the hero hero values
    // otherwise the placeholder values
    const byLine =
      hero?.byLine || hero?.descriptionHtml
        ? {value: hero.descriptionHtml}
        : placeholder.byline;

    const heading =
      hero?.heading || hero?.title ? {value: hero.title} : placeholder.heading;

    // merge hero placeholder with hero data
    return {
      heading,
      byLine,
      cta: hero?.cta || placeholder.cta,
      handle: hero?.handle || placeholder.handle,
      id: hero?.id || index,
      spread: hero?.spread || placeholder.spread,
      spreadSecondary: hero?.spreadSecondary || placeholder.spreadSecondary,
      height: placeholder?.height || undefined,
      top: placeholder?.top || undefined,
    };
  });
}

// get product info placeholder data
export function getProductInfoPlaceholder() {
  function getMultipleRandom(arr: any[], infos: number) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, infos);
  }
  return getMultipleRandom(PLACEHOLDERS.PRODUCT_INFO, 3);
}

export function getProductPlaceholder(): Product {
  return PLACEHOLDERS.PRODUCT as unknown as Product;
}
